.content {
    padding: 10px 0;
    a,
    a:visited {
        color: gray;
    }
    svg {
        margin-right: 10px;
    }
}
