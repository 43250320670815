.burger {
    position: absolute;
    padding: 5px 8px;
    top: 15px;
    right: -50px;
    width: 40px;
    height: 35px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    background-color: #f0f2f5;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    .burgerLine {
        width: 100%;
        height: 3px;
        background-color: #001529;
    }
    @media (max-width: 900px) {
        top: 10px;
    }
}
