.updateProspects {
  width: 100%;
  padding: 30px;

  .header {
    font: 400 13.3333px Arial;
    font-size: 16px;
    font-weight: 600;
    width: 130px;
    padding: 10px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  }

  .searchValue {
    margin-bottom: 8px;
  }

  .dynamicDeleteButton {
    position: absolute;
    margin: 0 12px;
    color: rgba(248, 29, 34, 0.8);
    cursor: pointer;
    transition: all 0.3s;
    top: 50%;
    left: 92%;
    right: 0;
    transform: translateY(-50%);
  }

  .dynamicDeleteButton:hover {
    color: rgba(180, 20, 27, 0.8);
  }

  .dynamicDeleteButton[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .tooltip {
    float: right;
    margin-right: 2px;
    margin-top: 4px;
  }
}