.expandedRecord {
    background-color: #fff;
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    border: 2px solid #cccccc;

    .column {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        flex: 0 0 30%;

        .row {
            min-height: 30px;
            display: flex;
            align-items: flex-start;
            padding: 5px 5px 0 0;
        }
        .title {
            margin-bottom: 10px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            font-size: 15px;
            line-height: 1.5715;
            height: 20px;
        }

        .label {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 13px;
            line-height: 1.5715;
            min-width: 100px;
        }

        .content {
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
            line-height: 1.5715;
            padding: 0 15px;
            max-width: 450px;
            a {
                overflow-wrap: break-word;
            }
        }
    }
}

td {
    padding: 0;
}

.hidden {
    display: none;
}
