.logs {
    .record {
        border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        display: flex;
        .row {
            min-height: 25px;
            display: flex;
            align-items: flex-start;
            padding: 5px 0 0 0;
        }

        .label {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5715;
            width: 50px;
        }

        .content {
            color: rgba(0, 0, 0, 0.65);
            font-size: 13px;
            line-height: 1.5715;
            padding: 0 15px;
            &.small {
                width: 120px;
            }
        }
        .tag {
            &.yellow {
                color: #faad14;
                border: solid 1px #faad14;
            }
            &.red {
                color: rgba(#f81d22, 0.8);
                border: solid 1px rgba(#f81d22, 0.8);
            }
        }
    }
}

@media (max-width: 900px) {
    .logs .record {
        padding: 5px;
        flex-direction: column;
        .label {
            font-size: 12px;
        }
        .content {
            font-size: 11px;
        }
    }
}
