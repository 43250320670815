.Login {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 300px;
        margin-top: -15vh;
        .error {
            text-align: center;
            min-height: 30px;
        }
        .header {
            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
            }
            transform: translateY(20px);
        }
        .loginFormButton {
            width: 100%;
        }
    }
}
