.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    transition: opacity 0.4s ease-in-out, transform 0s 0s;
    transform: scaleY(1);
    opacity: 1;
    &.hidden {
        opacity: 0;
        transition: opacity 0.4s ease-in-out, transform 0s 0.4s;
        transform: scaleY(0);
    }
}
