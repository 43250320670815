$color-blue: #1890ff;
$color-red: #f81d22;
$color-green: #0b8235;

.tableWrapper {
    position: relative;
    overflow: auto;
    width: 100%;
    border-collapse: collapse;
    table {
        width: 100%;
        position: relative;
        border-collapse: collapse;
        th {
            position: sticky;
            top: 0;
        }
    }

    .spinner {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
