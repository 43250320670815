.filtersWrapper {
    width: 100%;
    min-height: 100px;
    overflow-wrap: normal;
    padding: 20px;
    .title {
        padding: 0 0 5px 0;
        margin: 0 10px;
        font-size: 20px;
    }
    .filters {
        .select {
            width: 100%;
            & > div {
                padding: 10px 30px 10px 10px;
                border: 0.5px solid rgba(0, 0, 0, 0.1);
                & > span {
                    margin: 4px;
                }
            }
        }
    }
}

.errorIcon svg {
    color: #f81d22;
}

.inputWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px;
    .input {
        margin-right: 10px;
    }
    .inputBtn {
        flex-wrap: none;
        min-width: 100px;
        cursor: pointer;
        padding: 4px;
        border-radius: 3px;
        box-shadow: 0 0 2px rgb(64, 169, 255);
        border: 0.5px solid rgba(64, 169, 255, 0);
        svg {
            fill: rgb(64, 169, 255);
        }
        &:hover {
            border: 0.5px solid rgb(64, 169, 255);
            color: rgb(64, 169, 255);
            height: 100%;
        }
    }
}

@media (max-width: 800px) {
    .filtersWrapper {
        padding: 5px 0 10px 0;
        .title {
            font-size: 17px;
        }

        .filters > .select > div {
            padding: 5px;
            & > span {
                margin: 0;
            }
        }
    }
}
