.container {
    margin: 20px;

    .header {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
    }
    .scrapers {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .spinContainer {
        height: 100vh;
    }
    .card {
        background-color: #fff;
        width: 95%;
        margin: 50px 20px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width: 1400px) {
    .container {
        margin: 2px;
    }
}
