.barChart {
  height: 680px;
  width: 100%;
  margin: 10px 0 30px 0;
  padding: 40px 30px 120px 30px;

  .header {
    font-size: 30px;
    font-weight: 800;
    color: rgba(#0d1126, 0.8);
    margin-left: 40px;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    .boxes {
      display: flex;
      flex-wrap: wrap;

      .box {
        width: 220px;
        height: 40px;
        margin: 5px 0 5px 30px;
        border-radius: 5px;
        box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
        position: relative;

        display: flex;
        align-items: center;

        svg {
          width: 30px;
          height: 30px;
          margin-right: 5px;
          margin-left: 5px;
        }

        .name {
          width: 125px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          font-size: 16px;
        }

        .number {
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 100%;
          background-color: rgba(#ffffff, 0.2);
          color: white;
          font-size: 19px;
          padding-top: 5px;
          text-align: center;
        }
      }
    }
  }
  .chart {
    width: 100%;
    height: 100%;
  }
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  margin-right: 10px;
}

.tooltip {
  text-transform: lowercase;
  &.bold {
    font-weight: 600;
  }
}
