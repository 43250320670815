$color-blue: #1890ff;
$color-red: #f81d22;
$color-green: #0b8235;

thead th {
    font-weight: bold;
    background-color: #fff;
    border-bottom: 1px solid rgb(240, 240, 240);
    height: 45px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 1;

    &:hover {
        background-color: rgb(240, 240, 240);
    }

    &.small {
        padding-top: 5px;
        width: 30px;
    }
    &.medium {
        width: 7%;
        min-width: 110px;
    }
    &.large {
        width: 14%;
        min-width: 130px;
    }

    .carets {
        position: absolute;
        top: 30%;
        right: 10%;
        display: flex;
        flex-direction: column;
        height: 100%;

        .downCaret svg {
            transform: translateY(-5px);
        }
        svg {
            fill: rgb(191, 191, 191);
        }
        .active svg {
            fill: rgba(0, 0, 0, 0.6);
        }
    }

    .statusIcons {
        padding-top: 5px;
        .blue {
            color: $color-blue;
        }
        .red {
            color: $color-red;
        }
        .green {
            color: $color-green;
        }
        svg {
            margin-right: 5px;
            width: 1.4em;
            height: 1.4em;
        }
    }

    @media (max-width: 600px) {
        height: 35px;
        .carets {
            top: 20%;
        }
    }
}

.antTooltip {
    font-size: small;
}

.bagde sup {
    background-color: #3490ff;
}
