.logo {
    height: 60px;
    padding-top: 10px;
    margin: 5px 0 10px 0 ;
    overflow: hidden;
    transform: translateX(-5%);

    & > img {
        height: 90%;
    }
}
