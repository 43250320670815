.filters {
  width: 100%;
  padding: 20px 30px 10px 30px;

  form {
    display: flex;
    align-items: center;
    width: 100%;

    .formItem {
      width: 300px;
      text-transform: capitalize;
      flex-direction: column;
      margin-right: 20px;
    }

    .button {
      width: 100px;
    }
  }
}
