.menu {
    width: 155px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .navbarIcon svg {
        transform: translateY(10%);
        font-size: x-large;
    }

    .navLogout {
        position: absolute;
        bottom: 1%;
        left: 0;
    }
}

ul[class~="ant-menu-inline-collapsed"] {
    width: 60px;

    & > li {
        transform: translateX(-22%);
        &.navLogout {
            padding-left: 38px;
            translate: none;
        }
    }
}

@media (max-width: 1200px) {
    ul[class~="ant-menu-inline-collapsed"] .navLogout {
        position: relative;
    }
}
