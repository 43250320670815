.scrapersLogs {
    width: 100%;
    padding: 30px;
    .header {
        font: 400 13.3333px Arial;
        font-size: 16px;
        font-weight: 600;
        width: 130px;
        padding: 10px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    }

    div[class~="ant-col"]{
        min-width: 110px;
    }
}
