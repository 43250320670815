$color-red: #ff4d4f;

.header {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
    margin: 0;
    min-height: 50px;

    .leftContainer {
        position: absolute;
        top: 0;
        left: 52px;
        button {
            padding: 0;
            width: 40px;
            margin: 6px 4px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .rightContainer {
        position: fixed;
        top: 8px;
        right: 8px;
        button {
            width: 100px;
            margin: 6px 4px;
        }
        .settingsButton {
            background-color: #ff9800;
            border-color: #e58800;
        }
    }
    .antTitle {
        margin: 0 auto;
        width: 200px;
    }
    .antTooltip {
        font-size: small;
    }
    .ant-popover {
        svg {
            fill: $color-red;
        }
    }

    @media (max-width: 900px) {
        .leftContainer {
            top: 3px;
        }
        .rightContainer {
            top: 5px;
        }
        .antTitle {
            display: none;
        }
    }
}
div[class~="ant-popover-inner-content"] {
    padding: 10px;
    div[class~="ant-popover-message"] {
        padding: 8px 0 0 0;
        margin-bottom: 8px;
        div[class~="ant-popover-message-title"] {
            padding-left: 30px;
        }
        span[class~="anticon-mail"] svg {
            width: 20px;
            height: 20px;
        }
        span[class~="anticon-delete"] svg {
            color: $color-red;
            width: 20px;
            height: 20px;
        }
        div[class="ant-row ant-form-item"] {
            padding: 0;
            margin: 0;
        }
    }
}
