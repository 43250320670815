.chartCard {
  height: 680px;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 40px 30px 120px 30px;

  .header {
    font-size: 30px;
    font-weight: 800;
    color: rgba(#0d1126, 0.8);
    margin-left: 40px;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    .boxes {
      display: flex;
      flex-wrap: wrap;

      .box {
        width: 220px;
        height: 40px;
        margin: 5px 0 5px 30px;
        border-radius: 5px;
        box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
        position: relative;

        display: flex;
        align-items: center;

        svg {
          width: 30px;
          height: 30px;
          margin-right: 5px;
          margin-left: 5px;
        }

        .name {
          width: 125px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          font-size: 16px;
        }

        .number {
          position: absolute;
          right: 0;
          top: 0;
          width: 50px;
          height: 100%;
          background-color: rgba(#ffffff, 0.2);
          color: white;
          font-size: 19px;
          padding-top: 5px;
          text-align: center;
        }
      }
    }
  }
  .chart {
    width: 100%;
    height: 100%;
  }
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  margin-right: 10px;
}

.tooltip {
  text-transform: lowercase;
  &.bold {
    font-weight: 600;
  }
}

@media (max-width: 1100px) {
  .chartCard {
    padding: 40px 20px 160px 20px;
    .header {
      font-size: 22px;
      .boxes {
        .box {
          width: 190px;
          height: 25px;
          margin: 5px 0 5px 10px;

          svg {
            width: 20px;
            height: 20px;
          }
          .name {
            font-size: 13px;
            width: 110px;
          }
          .number {
            width: 40px;
            font-size: 14px;
            padding-top: 2px;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .chartCard {
    height: 650px;
    padding: 40px 20px 150px 20px;

    .header {
      flex-direction: column;
      margin-left: 20px;

      .boxes {
        justify-content: center;
        .box {
          margin: 5px 10px 5px 0;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .chartCard {
    height: 680px;
    padding: 15px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .chart {
      transform: rotate(90deg);
      height: calc(100vw - 80px);
      width: 500px;
      margin: 0px 0 5px 30px;
    }

    .header {
      margin-left: 0;
      font-size: 20px;
      margin-bottom: 50px;
      .boxes {
        .box {
          height: 20px;
          width: 150px;
          margin: 2px;
          svg {
            width: 15px;
            height: 15px;
          }
          .name {
            width: 90px;
            font-size: 11px;
          }
          .number {
            font-size: 12px;
            width: 32px;
            padding-top: 3px;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .chartCard {
    .chart {
      margin: 10% 0 5px 30px;
    }
  }
}
@media (max-width: 440px) {
  .chartCard {
    .chart {
      margin: 20% 0 5px 30px;
    }
  }
}
@media (max-width: 400px) {
  .chartCard {
    .chart {
      margin: 30% 0 5px 30px;
    }
  }
}
