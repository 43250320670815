$color-blue: #1890ff;
$color-red: #f81d22;
$color-green: #0b8235;

.report {
    height: 45px;
    background-color: #fff;
    border-bottom: 1px solid rgb(240, 240, 240);
    cursor: pointer;

    td {
        padding: 5px 10px;
        &.small {
            max-width: 40px;
        }
        &.exported span {
            color: #1890ff;
            padding-left: 5px;
        }
    }

    &.expanded {
        background-color: rgba(0, 0, 0, 0.06);
    }

    &.red,
    &.red.expanded {
        background-color: rgba($color-red, 0.07);
    }
    &.blue,
    &.blue.expanded {
        background-color: rgba($color-blue, 0.07);
    }
    &.green,
    &.green.expanded {
        background-color: rgba($color-green, 0.07);
    }

    .arrow {
        margin-top: 14px;
    }

    .tag {
        width: 90px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.blue {
            color: rgba($color-blue, 0.8);
            border: solid 1px rgba($color-blue, 0.8);
        }
        &.red {
            color: rgba($color-red, 0.8);
            border: solid 1px rgba($color-red, 0.8);
        }
        &.green {
            color: rgba($color-green, 0.8);
            border: solid 1px rgba($color-green, 0.8);
        }
    }

    .antTag {
        height: 25px;
    }

    .dash {
        background-color: #91d5fe;
        margin-top: 10px;
        margin-left: 15px;
        height: 2px;
        width: 20px;
        border-radius: 5px;
    }

    .statusIcons {
        padding-top: 5px;
        .blue {
            color: $color-blue;
        }
        .red {
            color: $color-red;
        }
        .green {
            color: $color-green;
        }
        svg {
            margin-right: 5px;
            width: 1.3em;
            height: 1.3em;
        }
    }
}

@media not all and (pointer: coarse) {
    .report:hover {
        background-color: rgb(240, 242, 245);
    }
}

.antTooltip {
    font-size: small;
}
