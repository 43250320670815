.scraper {
    padding: 30px;
    width: 40%;
    .antCard {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

        div[class~="ant-card-head-title"] {
            font-size: x-large;
            text-transform: capitalize;
            padding-left: 5%;
        }

        &.red {
            box-shadow: 0 0 6px #ff4d4f;
        }
        &.green {
            box-shadow: 0 0 6px #35b56e;
        }

        .status {
            margin-left: 5px;
            font-size: 15px;
            color: #35b56e;
            &.red {
                color: red;
            }
        }

        div[class~="ant-card-body"] {
            padding-top: 20px;
            padding-bottom: 0;
        }

        .timeline {
            transform: translateX(-15%);
            width: 70%;
            height: 100px;
            .timeline-item {
                font-size: 16px;
                font-weight: 600;
                padding: 0 0 10px 0;
            }
        }

        .image {
            position: absolute;
            width: 40%;
            height: 45%;
            max-height: 210px;
            top: 70px;
            opacity: 0.2;
            right: 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    @media (max-width: 1800px) {
        width: 45%;
    }

    @media (max-width: 1550px) {
        width: 80%;
    }

    @media (max-width: 800px) {
        width: 100%;
        .antCard {
            .image {
                top: 5%;
                left: 20px;
                width: 15%;
                height: 25%;
                opacity: 0.5;
            }

            .timeline {
                width: 100%;
                transform: none;
                height: 80px;

                .timeline-item {
                    font-size: small;
                }
            }
            div[class~="ant-card-body"] {
                padding-top: 10px;
            }
            div[class~="ant-card-head-wrapper"] {
                display: flex;
                flex-direction: column;

                div[class~="ant-card-head-title"] {
                    align-self: flex-end;
                    padding: 10px 0 0 0;
                    font-size: 18px;
                }
                div[class~="ant-card-extra"] {
                    align-self: flex-end;
                    padding: 0;
                }
            }
        }
    }
    @media (max-width: 410px) {
        .antCard {
            .image {
                top: 2%;
            }
            .timeline {
                transform: translateX(-15%);
                width: 300px;
                .timeline-item {
                    font-size: 12px;
                }
            }
            div[class~="ant-card-body"] {
                padding: 20px 10px 0 10px;
            }
        }
    }
}
