.filtersWrapper {
    position: fixed;
    top: 55px;
    right: 0;
    width: 320px;
    height: 560px;
    max-height: 90vh;
    background-color: #fff;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
    z-index: 12;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: transform 0.4s ease-in-out;
    transform-origin: left top;
    transform: scaleY(1);

    &.hidden {
        transform: scaleY(0);
    }

    .formButtons {
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-end;
        padding: 0 15px;
        button {
            min-width: 80px;
            height: 30px;
            margin: 0;
        }
    }

    .filters {
        padding-top: 15px;
        border-top: 1px solid #f0f0f0;
    }
    .searchWrapper {
        margin-bottom: 10px;
        .search {
            height: 35px;
            width: 230px;
        }
    }
    @media (max-width: 575px) {
        height: 550px;
        padding: 15px 15px 0 15px;
        .formButtons {
            margin: 15px 0;
            button {
                width: 10px;
                height: 30px;
            }
        }
        .filters {
            padding-top: 5px;
            margin-top: 5px;
        }
        .selectFilter {
            margin-bottom: 2px;
            & > div {
                padding-bottom: 2px;
            }
        }
        .searchWrapper {
            margin-bottom: 5px;
            .search {
                height: 30px;
            }
        }
    }
}
