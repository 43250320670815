.pageWrapper {
  width: 100%;
  height: 100%;
  background-color: rgb(240, 242, 245);
  display: flex;

  section {
    width: calc(100% - 155px);
    flex: 0 0 auto;
    transition: width 0.5s;
  }
  &.collapsed > section {
    width: calc(100% - 60px);
  }

  .navbar {
    height: 100%;
    position: relative;
  }

  .siteLayout {
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .header {
    background: #fff;
    height: 45px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 1200px) {
    &.collapsed > section,
    section {
      width: 100%;
    }
    ul[class~="ant-menu-inline-collapsed"] {
      width: 0;
    }

    .navbar {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
  @media (max-width: 900px) {
    .siteLayout {
      padding: 2px;
    }
  }
}
